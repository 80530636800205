@media (min-width: 320px) and (max-width: 599px) {
  .new_services_tabs {
    width: 100%;
  }
  .foot_desk {
    display: none !important;
  }
  .foot_sub_mobile {
    display: block !important;
  }
  .blog_new_body_sec {
    padding-left: 10px;
    padding-right: 10px;
  }
  .whomto_contact_section a {
    font-size: 16px;
    line-height: 24px;
  }
  .innovationCarousel .carousel-caption {
    right: 8%;
    left: 8%;
  }
  .innovationCarousel p {
    font-size: 16px;
    line-height: 26px;
  }
  .innovationCarousel h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .new_our_story_main_section h2,
  .new_core_headding,
  .leadership_carousel_section h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .new_our_story_main_section h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .new_our_story_main_section p {
    font-size: 16px;
    line-height: 28px;
  }

  .new_core_lft_p {
    font-size: 16px;
    line-height: 26px;
    padding-right: 0px;
    padding-top: 0px;
  }

  .core_rht_cnt_sub {
    display: block !important;
  }

  .core_rht_cnt_sub img {
    margin-right: 0px;
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }

  .new_core_main_section {
    padding: 40px 0px;
  }

  .new_our_story_main_section {
    padding-top: 40px;
  }

  .new_our_story_main_section img {
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .new_our_story_main_section .row {
    margin-bottom: 10px;
  }

  .full-screen-carousel .flip-card-back p {
    font-size: 14px;
  }

  .core_rht_cnt_sub p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .success_story_main {
    padding-bottom: 20px !important;
  }

  .data_lake_capabilities_section ul li,
  .liquor_section p {
    font-size: 16px;
    line-height: 26px;
  }

  .cloud_real_expertise_building p,
  .realtime_expert_building p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .howwe_solvenew_section h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .howwe_solvenew_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .devoops_tech {
    padding-top: 20px;
  }

  .devoops_tech_sec {
    padding-top: 8px;
  }

  .privacy_statement_body_main_section p {
    font-size: 16px;
    line-height: 26px;
  }

  .privacy_statement_body_main_section h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .privacy_statement_body_main_section h4 {
    font-size: 16px;
    line-height: 26px;
  }

  .privacy_statement_body_main_section ul li {
    font-size: 16px;
    line-height: 26px;
  }

  .new_services_tab__list ul li button {
    background-color: #ffffff;
    color: #e3853b;
    transition: 0.4s all ease-in-out;
    border: 1px solid #e3853b;
    position: relative;
    padding: 8px 6px;
    border-radius: 6px;
    width: -webkit-fill-available;
    text-align: center;
  }

  .new_services_tab__list ul li button:hover:before {
    width: 100%;
    left: 0;
    background: #414141;
    border-radius: 5px;
    bottom: -1px;
  }

  .new_services_tab_section {
    margin-bottom: 15px;
  }

  .lets_talk_body {
    padding-top: 0px;
  }

  .contact_rht_inputs_main p {
    font-size: 20px;
    line-height: 28px;
  }

  .childflex {
    display: block;
    padding: 0px;
    gap: 0;
  }

  .headquaters_sec {
    margin-bottom: 20px;
  }

  .contact_body_main_bg_section {
    padding: 0px 0 50px 0px;
  }

  .contact_rht_inputs_main {
    padding: 0px 15px 40px 15px !important;
    margin-top: 0px !important;
  }

  .contact_rht_inputs_main h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .headquaters_sec a {
    font-size: 18px;
  }

  .headquaters_sec p {
    font-size: 16px;
    line-height: 26px;
  }

  .input_radio label {
    margin-right: 0px;
  }

  .expert_logos_new {
    margin-bottom: 0px !important;
  }

  .caption_div {
    display: block !important;
  }

  .caption_div img {
    margin-bottom: 20px;
  }

  .caption_div p {
    padding-bottom: 0px;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
  }

  .home_partners_carousel_section .carousel-inner {
    min-height: 500px;
  }

  .expertise-ul span {
    flex: 100%;
    padding-right: 8px;
  }

  .data_cycle_box p {
    font-size: 14px;
    line-height: 24px;
  }

  .section_title {
    font-size: 28px;
  }

  .whydata_img_div {
    margin-bottom: 10px;
  }

  .blog_cnt_body a {
    font-size: 20px;
    line-height: 30px;
  }

  .simplying_healthcare_section p {
    font-size: 16px;
    line-height: 28px;
  }

  .our_clients_main {
    display: block;
  }

  .our_clients_left {
    justify-content: center;
    margin-bottom: 30px;
  }

  .white_paper_right {
    text-align: center;
    width: 100%;
  }

  .new_services_tab__list ul {
    display: block !important;
  }

  .new_services_tab__list ul li a {
    font-size: 16px !important;
    margin-right: 0px !important;
  }

  .new_services_tab__list ul li {
    margin-bottom: 15px;
  }

  .new_services_tab__list ul li:last-child {
    margin-bottom: 0px;
  }

  .letstalk_foot_sub {
    display: block !important;
  }

  .letstalk_sub_sec {
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) and (max-width: 991px) {
  .block:nth-child(2n + 1) {
    grid-column: 1/4;
  }

  .block:nth-child(2n + 2) {
    grid-column: 4/7;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .all_stories_select_section h1 {
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }

  .all_stories_select_section select {
    font-size: 20px;
  }

  .all_stories_sub {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -46px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .our_competitive_edge_section {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    margin-bottom: 20px !important;
  }

  .ban_mobile_pad0 {
    padding: 0px !important;
  }

  .dataquality_section {
    padding: 20px 0 20px;
  }

  .footer {
    padding: 30px 0px;
  }

  .achive_sub_div h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .migration_reason .achive_sub_div img {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }

  .full-screen-carousel .flip-card-back {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .white_paper_right {
    text-align: center;
    width: 100%;
  }

  .our_clients_right {
    width: 100%;
  }

  .form_main {
    display: block !important;
    width: 100%;
    margin-bottom: 50px;
  }

  .floating-label-group {
    margin-bottom: 50px;
  }

  .white_paper_left {
    width: 100%;
  }

  .our_clients_left {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .form_main_div {
    width: 90%;
  }

  .chat_panel_main {
    width: 320px;
    right: 0px;
  }
}

@media (min-width: 600px) and (max-width: 690px) {
  .partners_main {
    max-width: calc(100% - 10%) !important;
  }

  .data_cycle_box p {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .all_stories_select_section {
    display: block;
    margin-bottom: 30px;
  }
  .nav-item:last-child {
    margin-right: 20px;
  }
  .dropdown_main {
    color: #fff !important;
    border: 0 !important;
    font-size: 16px !important;
    margin-left: 10px;
  }

  .blogs_categories_rht {
    display: none;
  }

  .new_blog_thumb_row a {
    width: 100%;
  }

  .new_blog_thumb_row a img {
    width: 100%;
  }

  .blog_lft_sec_main {
    padding-right: 20px;
    padding-left: 20px;
  }

  .blog_detail_rht_div {
    position: relative;
    height: 100%;
    top: 0px;
  }

  .business_inteligence_slider_rht {
    border-radius: unset;
  }

  .blogs_ban_btm_strip .aboutusnew_ban_btm_txt {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .blogs_ban_btm_strip {
    margin-bottom: 0px !important;
  }

  .prod_new_whatwedo_btm_img_Section {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .howwe_solvenew_sub_main {
    border: 0px;
    margin-bottom: 30px;
  }

  .partners_main a {
    flex-direction: column;
    gap: 20px;
    max-width: calc(100% - 18%) !important;
    padding-top: 20px !important;
  }

  .simplying_healthcare_section {
    padding-bottom: 0px;
    padding-top: 40px;
  }

  .successstory_section h2 {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 40px;
  }

  .enlume_logo {
    height: 26px;
  }

  .our_clients_left {
    width: 100% !important;
  }

  .blogs_main img {
    min-height: 520px;
    max-height: 520px;
    object-fit: cover;
  }

  .blog_publish_date {
    font-size: 14px;
  }

  .what_we_offer_main ul li button {
    padding: 10px 30px;
  }

  .partners_left h1 {
    font-size: 20px;
  }

  .partners_left p {
    font-size: 16px;
    line-height: 22px;
    height: 50px;
  }

  .partners_section .carousel-caption {
    padding-bottom: unset;
  }

  .partners_left,
  .partners_right {
    width: 100%;
  }

  .banner_main h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .service_body_main .services {
    flex: 100%;
  }

  .industries_body_main .industries {
    flex: 100%;
  }

  .blog_content {
    padding-right: 20px;
  }

  .how_can_txt {
    width: 85%;
  }

  .white_paper_left {
    justify-content: center;
    margin-bottom: 30px;
  }

  .white_paper_right {
    text-align: center;
  }

  .our_clients_right {
    display: flex;
    justify-content: center;
  }

  .robot_section {
    width: 260px;
  }

  .banner_main .row {
    width: 95%;
  }

  .services_cloud_main_row h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .services_cloud_main_row p {
    font-size: 16px;
    line-height: 26px;
  }

  .services_cloud_main_row {
    text-align: center;
    margin-bottom: 50px;
    padding-bottom: 20px;
  }

  .services_cloud_main_row img {
    margin-top: 20px;
  }

  .services_ban_btm_txt .enlume_partners_txt_main h5 {
    margin-bottom: 0px;
  }

  .what_we_do_sub {
    margin-bottom: 30px;
  }

  .what_we_do_row p {
    padding: 10px 15px;
    font-size: 18px;
    line-height: 28px;
  }

  .what_we_do_row h3 {
    font-size: 20px;
  }

  .blogs_section .carousel-caption {
    top: 18%;
    right: 0;
    width: 100%;
  }

  .blogs_section .carousel-caption h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .white_paper_right p {
    max-width: 100%;
  }

  .success_stories_sub1 {
    width: 80% !important;
    margin: 0 auto 50px auto;
  }

  .our_enterprie_section ul li {
    font-size: 16px;
    line-height: 26px;
  }

  .webapp_how_can_help_section .whydata_title {
    font-size: 34px;
    line-height: 44px;
  }

  .webapp_how_can_help_section .whydata_title {
    font-size: 34px;
    line-height: 44px;
  }

  .why_web_app_section {
    padding: 0px;
  }

  .why_web_app_section p {
    font-size: 16px;
    line-height: 26px;
  }

  .leveringthe_power_section {
    margin-bottom: 0;
    padding-bottom: 15px;
  }

  .our_qa_practies_section .achive_sub_main:nth-child(1),
  .our_qa_practies_section .achive_sub_main:nth-child(2),
  .our_qa_practies_section .achive_sub_main:nth-child(3),
  .our_qa_practies_section .achive_sub_main:nth-child(4) {
    border: unset;
  }

  .our_qa_practies_section {
    padding-top: 0px;
  }

  .migration_reason {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-bottom: 0;
  }

  .migration_reason {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-bottom: 0;
  }

  .what_it_does_section {
    margin-bottom: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .edie_body_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .enterprice_mobility_rht {
    padding-left: 15px !important;
  }

  .industries_learnbtn {
    margin-bottom: 20px;
  }

  .industries_rht_pad h1,
  .industries_rht_pad p {
    padding-left: 0px;
  }

  .finance_learn_btn {
    padding-left: 0;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: #777 !important;
  }

  .blogdetails_main h1,
  .blogdetails_main h2,
  .adantages_gitflow_section h3 {
    font-size: 22px;
    line-height: 30px;
  }

  .blogdetails_main p {
    font-size: 16px;
    word-break: break-all;
  }

  .system_prerequisites_setion strong {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .blogdetails_main ul li {
    font-size: 16px;
  }

  .image-center {
    width: 100%;
  }

  .simplying_healthcare_section h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .lets_connectnew_section {
    text-align: center;
  }

  .lets_connectnew_lft_sec span {
    margin-bottom: 10px;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .service_body_main .services {
    flex: 50%;
  }

  .industries_body_main .industries {
    flex: 50%;
  }

  .footer_main {
    display: inline-block;
  }

  .footer_div {
    width: 50%;
    float: left;
  }

  .banner_main .row {
    width: 90%;
  }

  .services_cloud_main_row h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .services_cloud_main_row p {
    font-size: 16px;
    line-height: 26px;
  }

  .what_we_do_row p {
    padding: 10px 10px;
    font-size: 18px;
  }

  .what_we_do_row h3 {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .our_clients_left {
    width: 100% !important;
  }

  .success_stories_sub1 {
    width: calc(50% - 30px) !important;
  }

  .enterprice_mobility_rht {
    padding-left: 15px !important;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .banner_main .row {
    width: 85%;
  }

  .services_cloud_main_row h2 {
    font-size: 32px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog_details_social_icons img {
    margin-right: 10px;
  }
  .foot_tab_section {
    display: flex;
    gap: 30px;
  }
  .foot_tab_section .footer_sub {
    margin-top: 0px;
  }
  .customized_app_section img {
    margin-bottom: 20px;
  }

  .new_blog_thumb_row a {
    width: 100%;
    margin-bottom: 20px;
  }

  .blog_lft_sec_main {
    padding-right: 20px;
  }

  .blogs_categories_rht ul {
    padding-left: 10px;
  }

  .categories_sub_label {
    font-size: 18px;
    line-height: 26px;
  }

  .categories_checkmark {
    top: 0px;
  }

  .new_blog_thumb_row a img {
    width: 100%;
  }

  .related_blog_card_body {
    margin-bottom: 30px;
  }

  .blog_detail_header_section h2,
  .blog_new_body_sec h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .related_blogs_section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog_detail_rht_div h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .cloud_mig_how_can_help_section {
    padding-bottom: 0px;
  }

  .all_stories_select_section h1 {
    font-size: 26px;
  }

  .partners_left h1 {
    font-size: 18px;
  }

  .simplying_healthcare_section {
    padding-bottom: 0px;
  }

  .partners_left p {
    font-size: 14px;
    line-height: 22px;
  }

  .blog_content {
    padding-right: 20px;
  }

  .banner_main h1 {
    font-size: 34px !important;
    line-height: 44px;
  }

  .how_can_txt {
    width: 70%;
  }

  .product_engineering_banner .banner_main h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .new_whatwe_do_txt {
    width: 100% !important;
  }

  .new_whatwedo_Section .whatwedo_btn a {
    padding: 8px 12px;
  }

  .product_engineering_banner {
    padding: 0px 10px !important;
  }

  .whatwedo_sub_body_sec h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .whatwedo_sub_body_sec p {
    font-size: 16px;
    line-height: 26px;
  }

  .new_prod_what_wedo_sub_main .whatwedo_sub_body_sec p {
    margin-bottom: 0px;
  }

  .new_how_wedo_sub_lft img {
    margin-bottom: 30px;
  }

  .new_cloud_servies_bg:before,
  .new_cloud_sec::before {
    display: none !important;
  }

  section.new_services_ban_btm_section.new_cloud_sec.mar_btm_80 {
    margin-bottom: 0px !important;
    padding: 50px 0px;
  }

  .new_cloud_servies_bg {
    margin-bottom: 0px !important;
    padding: 20px 0px 30px 0;
  }

  .new_loud_enb_help {
    margin-top: 50px;
  }

  .new_whatwe_do_txt {
    margin-bottom: 0px;
  }

  .new_cloud_sec .product_eng_txt_main {
    margin-bottom: 50px;
  }

  .new_cloud_servies_bg img {
    position: relative;
    margin-bottom: 20px;
  }

  .blog_post_txt p {
    font-size: 18px;
  }

  /* .blog_post_txt {
    min-height: 160px;
} */
  .blog_post_a_div {
    width: 70%;
  }

  .aboutnew_ban_head {
    font-size: 42px !important;
    line-height: 52px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 24px;
    line-height: 34px;
  }

  .core_values_sub_main h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  .aboutnew_unvelling h2 {
    font-size: 32px;
    line-height: 46px;
  }

  .aboutnew_unvelling p {
    font-size: 22px;
    line-height: 32px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .aboutnew_ban_btm_txt_sec::before {
    width: 98%;
  }

  .corealues_title_section h1 {
    margin-bottom: 15px;
  }

  .core_values_sub {
    padding: 20px;
  }

  .about_unvelling_spark_section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .whydata_title {
    font-size: 28px;
  }

  .achieve_competitive_section h2 {
    font-size: 28px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 20px;
    line-height: 30px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 34px;
    line-height: 50px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .whydata_lft_img {
    padding: 0;
    margin-bottom: 30px;
  }

  .data_preparation_rht_form {
    padding: 20px !important;
  }

  .sucessstory_body_txt_div span {
    padding: 15px;
    font-size: 16px;
    line-height: 24px;
  }

  .industries_learnbtn {
    margin-bottom: 50px;
  }

  .industries_rht_pad h1,
  .industries_rht_pad p {
    padding-left: 0px;
  }

  .finance_learn_btn {
    padding-left: 0;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: #777 !important;
  }
}

@media (max-width: 1550px) {
  .partners_main {
    max-width: calc(100% - 10%) !important;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .breadcrum-nav .breadcrumb-highlight-text,
  .breadcrum-nav a {
    font-size: 12px;
  }
  .breadcrumb_last_element {
    max-width: 120px;
  }
  .breadcrumb_element {
    max-width: 120px;
  }
  .breadcrum-nav a {
    margin-right: 5px;
  }
  .nav_arrow {
    width: 6px;
    height: 10px;
    margin-right: 5px;
  }
  .breadcrumb_container {
    top: 50px;
  }
  .home_breadcrumb_icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  .slide_up_arrow_sec {
    display: none;
  }

  .edie_body_section ul li,
  .edie_body_section p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .edie_body_section {
    padding-top: 15px !important;
    padding-bottom: 20px;
  }

  .success_btmof_ban .aboutusnew_ban_btm_txt {
    padding: 0px 20px;
  }

  .unlock_benifits_section h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .assesment_section ul li,
  .building_poc ul li {
    font-size: 16px;
    line-height: 26px;
  }

  .cloud_mig_how_can_help_section p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .cloud_mig_how_can_help_section {
    padding: 15px 0px;
  }

  .cloud_mig_how_can_help_section h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .newsucess_stories_sec {
    background-color: #f3f4f5;
    margin: 20px 0px;
    padding: 40px 0px;
  }

  .success_storie_p {
    font-size: 20px;
    line-height: 30px;
  }

  .sucess_storie_title {
    margin-bottom: 20px;
  }

  .newsucess_stories_sec a {
    font-size: 16px;
  }

  .simplying_healthcare_section img {
    margin-bottom: 20px;
  }

  .industries_healthare img {
    margin-top: 20px;
  }

  .all_stories_select_section h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .lets_connectnew_lft_sec span {
    font-size: 26px;
    line-height: 36px;
  }

  .white_paper_main {
    display: block;
  }

  .floatlabelmain_gap {
    display: block !important;
  }

  .footer_main {
    display: inline-block;
  }

  .footer_div {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .copyrights a {
    padding-right: 5px;
  }

  .copyrights span {
    padding-right: 0px;
    padding-left: 3px;
  }

  .enlume_partners_txt_main {
    width: 90%;
  }

  .error_msg_div h1 {
    font-size: 60px;
    line-height: 70px;
  }

  .error_msg_div h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .error_msg_div span {
    font-size: 20px;
    line-height: 30px;
  }

  .image-center {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .enterprice_mb_bg {
    background: #f3f3f4;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    margin-bottom: 50px;
  }
  .landing_lft_pad {
    padding-left: 15px;
  }
  .flex_direction_reverse .row {
    flex-direction: column-reverse;
  }

  .flex_direction_reverse img {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .flex_direction_reverse .whydata_img_div img {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .enterprice_howcan_help {
    margin-bottom: 0px;
  }

  .enterprice_howcan_help_section {
    padding-bottom: 10px;
  }

  .whydata_rht_main {
    margin-bottom: 15px;
  }

  .simplying_healthcare_section .row {
    flex-direction: column-reverse;
  }

  .industries_healthare img {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .simplying_healthcare_section img {
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .industries_healthare {
    padding-top: 0 !important;
  }

  .simplying_healthcare_section {
    padding-top: 25px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .innovationCarousel h3 {
    font-size: 28px;
    line-height: 38px;
  }
  .innovationCarousel .carousel-caption {
    left: 6%;
    right: 6%;
  }
  .landingpage_section .carousel-item {
    min-height: 650px;
  }
  .innovationCarousel .carousel-item img {
    min-height: 620px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }

  .related_blog_card_body {
    margin-bottom: 30px;
  }

  .blog_detail_header_section h2,
  .blog_new_body_sec h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .related_blogs_section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog_detail_rht_div h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .blog_new_body_sec .row {
    flex-direction: column-reverse;
  }

  .related_blog_img img {
    width: 100%;
  }

  .related_blog_btm_sec {
    position: relative;
    width: 100%;
  }

  .why_vassist_main_grid {
    grid-template-columns: 1fr;
  }

  .business_inteligence_slider_lft {
    border-radius: unset;
    padding-right: 15px;
  }

  .business_inteligence_slider_rht img {
    height: auto;
  }

  .business_inteligence_slider h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .business_inteligence_slider ul li {
    font-size: 16px;
    line-height: 24px;
  }

  .customized_app_section img {
    margin-bottom: 20px;
  }

  .business_sub_text h2,
  .customized_app_sub_head h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .pre_navbar #services-sub-menu,
  .pre_navbar #industries-sub-menu,
  .pre_navbar #resources-sub-menu {
    position: static;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  .pre_navbar.header_nav .nav-link:hover:before {
    background: transparent !important;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 220px;
    margin-bottom: 0px;
  }

  .whopage_build_img {
    position: relative;
    left: unset;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .landingpage_section button,
  .readyto_get_started_section p {
    margin-bottom: 30px;
  }

  .readyto_get_started_section .align_items_center {
    align-items: left;
  }

  .bridge_gap_section h2,
  .whothe_page_builder_section h2,
  .landingpage_section h2,
  .why_enl_pagebuild_section h2,
  .readyto_get_started_section h2,
  .faqs_section h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .faqs_section .accordion-button {
    font-size: 22px;
    line-height: 30px;
  }

  .landingpage_section {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .why_enl_pagebuild_section {
    padding: 60px 0px;
  }

  .readyto_get_started_section button {
    margin: unset;
  }

  .our_story_box {
    min-height: 420px;
    padding: 25px 15px 0px 15px;
    margin-bottom: 30px;
  }

  .our_story_new_main {
    padding-bottom: 0px;
  }

  .our_story_box h3 {
    min-height: auto;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 100%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: auto;
    padding: 80px 20px 50px 20px;
    margin-bottom: 60px;
  }

  .ourstory_box_btm_img {
    display: inline-block;
    width: 100%;
    left: 0px;
    text-align: center;
  }

  .core_value_main_heading,
  .our_ceo_section_main h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .new_about_core_values_sec {
    padding-bottom: 20px;
  }

  .new_our_story_main_section h2,
  .new_core_headding,
  .leadership_carousel_section h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .new_our_story_main_section h3 {
    font-size: 22px;
    line-height: 32px;
  }

  .new_our_story_main_section p {
    font-size: 16px;
    line-height: 28px;
  }

  .new_core_lft_p {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0px;
    padding-top: 0px;
  }

  .new_our_story_main_section img {
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .new_our_story_main_section .row {
    margin-bottom: 10px;
  }

  .full-screen-carousel .flip-card-back p {
    font-size: 14px;
  }

  .core_rht_cnt_sub p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .mobility_enterprice_section {
    padding-bottom: 0px;
  }

  .mobility_enterprice_section .achive_sub_main:nth-child(5),
  .mobility_enterprice_section .achive_sub_main:nth-child(6),
  .mobility_enterprice_section .achive_sub_main:nth-child(7),
  .mobility_enterprice_section .achive_sub_main:nth-child(8) {
    border-bottom: unset;
  }

  .terms_content_body h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .terms_content_body p,
  .terms_content_body ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .cloud_mig_how_can_help_section {
    padding: 20px 0px;
  }

  .new_services_tab_section {
    margin-bottom: 15px;
  }

  .newsucess_stories_sec {
    background-color: #f3f4f5;
    margin: 40px 0px;
  }

  .home_partners_carousel_section .carousel-inner {
    min-height: 260px;
  }

  .loadmore_div {
    margin-bottom: 50px;
  }

  .blog_post_main_row {
    margin-bottom: 30px;
  }

  .lets_connectnew_lft_sec span {
    font-size: 26px;
  }

  .all_stories_select_section h1 {
    font-size: 26px;
  }

  .sucessstory_body_txt_div span {
    padding: 15px;
    font-size: 16px;
    line-height: 24px;
  }

  .sucessstory_body_txt_div a {
    left: 10px;
    font-size: 16px;
  }

  .services_martop_lets {
    margin-top: 60px;
  }

  .about_joureny_body h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .about_joureny_body {
    width: 100%;
  }

  .caption_div img {
    width: 200px;
    height: 200px;
  }

  .caption_div p {
    font-size: 18px;
    line-height: 28px;
  }

  .new_partners_pad {
    margin-bottom: 0px;
  }

  .footer_main {
    display: inline-block;
  }

  .footer_div {
    width: 50%;
    float: left;
  }

  .enlume_partners_txt_main {
    width: 90%;
  }

  .product_engineering_banner .banner_main h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .new_whatwe_do_txt {
    width: 100% !important;
  }

  .new_whatwedo_Section .whatwedo_btn a {
    padding: 8px 12px;
  }

  .product_engineering_banner {
    padding: 0px 10px !important;
  }

  .whatwedo_sub_body_sec h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .new_prod_what_wedo_sub_main .whatwedo_sub_body_sec p {
    margin-bottom: 0px;
  }

  .new_how_wedo_sub_lft img {
    margin-bottom: 30px;
  }

  .new_prod_what_wedo_sub_main1 {
    border: unset;
    margin-bottom: 50px;
  }

  .blog_post_main {
    margin-bottom: 60px;
  }

  .new_blogs_body_section {
    padding-bottom: 0px;
  }

  .aboutnew_ban_head {
    font-size: 42px !important;
    line-height: 52px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 24px;
    line-height: 34px;
  }

  .core_values_sub_main h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  .aboutnew_unvelling h2 {
    font-size: 32px;
    line-height: 46px;
  }

  .aboutnew_unvelling p {
    font-size: 22px;
    line-height: 32px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .aboutnew_ban_btm_txt_sec::before {
    width: 98%;
  }

  .corealues_title_section h1 {
    margin-bottom: 15px;
  }

  .core_values_sub {
    padding: 20px;
  }

  .about_unvelling_spark_section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .core_values_sub_main:nth-child(1),
  .core_values_sub_main:nth-child(3) {
    background: #000;
  }

  .core_values_sub_main:nth-child(3) h2,
  .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .core_values_sub_main:nth-child(4) h2,
  .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .core_values_sub_main:nth-child(4) {
    background: unset;
  }

  .aboutnew_ban_btm_txt_sec {
    margin-top: unset;
    padding-top: 40px !important;
    padding-bottom: 0px !important;
  }

  .whydata_title {
    font-size: 28px;
  }

  .achieve_competitive_section h2 {
    font-size: 28px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 20px;
    line-height: 30px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 34px;
    line-height: 50px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .whydata_lft_img {
    padding: 0;
    margin-bottom: 30px;
  }

  .data_preparation_rht_form {
    padding: 20px !important;
  }

  .achive_sub_main {
    border-right: unset;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .achive_bodydiv {
    padding: 10px 0 30px;
  }

  .data_preparation_form_section::before {
    background: unset;
    display: none;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(3) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(4) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .data-preparation_tech .devoops_tech_flex {
    display: block;
  }

  .leveringthe_power_section .achive_sub_main:nth-child(1),
  .leveringthe_power_section .achive_sub_main:nth-child(2),
  .leveringthe_power_section .achive_sub_main:nth-child(3) {
    border-bottom: unset;
  }

  .sucessstory_sub_main {
    margin-bottom: 50px;
  }

  .sucessstory_sub_main img {
    width: 100%;
  }

  .sucessstory_sub_main:nth-child(2) {
    margin-bottom: 80px;
  }

  .sucessstory_sub_main:last-child {
    margin-bottom: 0;
  }

  .containerGrid {
    grid-gap: 20px;
  }
}

@media (max-width: 991px) {
  .navbar-toggler {
    background-color: #fff !important;
    font-size: 13px !important;
    width: 46px;
  }

  .nav-item a {
    color: #777;
  }

  .nav-link.active {
    color: #777;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: #777;
  }

  .navbar-nav {
    margin-top: 30px;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .new_banner_main h1 {
    margin-bottom: 50px;
    font-size: 50px;
    line-height: 60px;
  }

  .whatwedo_sub_body_sec {
    min-height: 230px;
  }

  .partners_new_Sec h2 {
    margin-top: 10px;
  }

  .whitepaper_txt_div h5 {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    line-height: 32px;
  }

  .new_blog_lft_main h3 {
    font-size: 40px !important;
  }

  .howcan_help_txt {
    font-size: 16px;
  }

  .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .our_clients_new img {
    width: 100%;
  }

  .tech_sec .grid-container {
    grid-template-columns: repeat(4, 2fr 2fr);
  }

  .blog_post_img {
    height: 350px;
    max-height: 350px;
  }

  .new_partners_img_txt_section {
    padding-bottom: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .our_clients_Section img,
  .home_expert img {
    width: 100%;
    padding: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1022px) {
  .new_banner_main h1 {
    margin-bottom: 50px;
    font-size: 50px;
    line-height: 60px;
  }

  .whatwedo_sub_body_sec {
    min-height: 230px;
  }

  .partners_new_Sec h2 {
    margin-top: 10px;
  }

  .whitepaper_txt_div {
    padding: 10px 15px 0px 15px;
  }

  .whitepaper_txt_div h5 {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    font-size: 18px;
  }

  .new_blog_lft_main h3 {
    font-size: 40px !important;
  }

  .howcan_help_txt {
    font-size: 16px;
    padding: 15px;
  }

  .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .new_blogs_main_div .blogDate {
    margin-left: -15px;
  }

  .new_services_ban_btm_section p {
    padding-right: 0px;
  }

  .new_serve_digital p {
    font-size: 20px;
    line-height: 30px;
  }

  .new_services_tab__list ul li a {
    font-size: 16px;
    margin-right: 12px;
  }

  .new_services_ban_btm_txt_sec p {
    font-size: 23px;
    line-height: 43px;
  }

  .blog_post_txt p {
    font-size: 18px;
    padding-bottom: 10px;
    line-height: 28px;
  }

  .blog_post_txt {
    padding: 15px 12px;
  }

  .blog_post_txt {
    left: 20px;
  }

  .blog_post_img {
    max-height: 250px;
    height: 250px;
  }

  .aboutnew_ban_head {
    font-size: 42px !important;
    line-height: 52px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 24px;
    line-height: 34px;
  }

  .core_values_sub_main h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
  }

  .aboutnew_unvelling h2 {
    font-size: 32px;
    line-height: 46px;
  }

  .aboutnew_unvelling p {
    font-size: 22px;
    line-height: 32px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .aboutnew_ban_btm_txt_sec::before {
    width: 98%;
  }

  .contactus_form_section p {
    font-size: 34px;
    line-height: 44px;
  }

  .whydata_title {
    font-size: 28px;
  }

  .achieve_competitive_section h2 {
    font-size: 28px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 20px;
    line-height: 30px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 34px;
    line-height: 50px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .new_partners_img_txt_section {
    padding-bottom: 50px;
  }

  .lets_connectnew_lft_sec span {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .landingpage_section .carousel-item {
    min-height: 680px;
  }
  .new_services_tab_section {
    margin-bottom: 15px;
  }

  .new_banner_main h1 {
    margin-bottom: 15px;
    font-size: 34px;
    line-height: 44px;
  }

  .new_whatwedo_pad_btm {
    padding-bottom: 60px;
  }

  .whatwedo_sub_body_sec {
    min-height: auto;
  }

  .partners_new_Sec h2 {
    margin-top: 0px;
    font-size: 32px;
    line-height: 38px;
  }

  .whitepaper_txt_div {
    padding: 10px 0px 0px 10px;
  }

  .whitepaper_txt_div h5 {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    font-size: 22px;
  }

  .new_blog_lft_main h3 {
    font-size: 28px !important;
  }

  .howcan_help_txt {
    font-size: 15px;
    padding: 10px;
    line-height: 26px;
  }

  .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .ourclients_mar {
    font-size: 40px !important;
    line-height: 50px;
  }

  .client_logos_section {
    margin-top: 100px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .new_blogs_main_div .blogDate {
    margin-left: -10px;
  }

  .new_what_we_do_section .what_wedo_sub_main {
    margin-bottom: 100px;
  }

  .new_what_we_do_section .what_wedo_sub_main img {
    width: 100%;
  }

  .whitepapaer_rht_div {
    padding: 20px;
  }

  .whitepaper_txt_div h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .whitepaper_txt_div a {
    margin-top: 0px;
  }

  .new_blogs_main_div .blogSection {
    gap: 5px;
  }

  .new_blogs_main_div .blogBody {
    padding: 0px 10px;
    height: auto;
    min-height: 300px;
  }

  .new_blog_lft_main h2 {
    font-size: 38px;
  }

  .how_Can_txt {
    font-size: 30px;
  }

  .lets_talk_close_div button {
    margin-right: 30px !important;
    padding-top: 20px;
  }

  .new_services_ban_btm_section p {
    padding-right: 0px;
    padding-top: 0px;
    font-size: 20px;
    line-height: 28px;
  }

  .product_eng_txt_main h1 {
    font-size: 30px;
  }

  .product_eng_txt_main {
    padding-left: 0px;
  }

  .product_eng_txt_main p {
    font-size: 16px;
    padding-right: 0px;
  }

  .new_serve_product_section .expertise_sub ul li {
    font-size: 14px;
  }

  .new_services_banner .banner_main h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .new_services_tab__list ul li a {
    font-size: 16px;
    margin-right: 50px;
  }

  .new_home_txt_div {
    padding-top: 80px;
  }

  .new_whitepaper_div {
    padding-bottom: 0;
  }

  .enl_part_img_div {
    top: 50px;
  }

  .new_serve_digital::before {
    background: #fff;
  }

  .new_serve_digital img {
    padding: 0px;
    margin-top: 60px;
  }

  .serices_before_div img {
    padding: 0;
  }

  .serices_before_div {
    padding-top: 0px !important;
    margin-bottom: 50px !important;
  }

  .serices_before_div1 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .serices_before_div2 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .new_services_ban_btm_txt_sec p {
    font-size: 24px;
    line-height: 40px;
  }

  .blog_post_txt p {
    font-size: 16px;
    line-height: 26px;
  }

  .blog_post_txt {
    padding: 10px;
    left: 10px;
    bottom: -30px;
  }

  .blog_post_img {
    max-height: 250px;
    height: 250px;
  }

  .about_unvelling_spark_section::before {
    background: #fff;
  }

  .contactus_form_section p {
    font-size: 34px;
    line-height: 44px;
  }

  .industry_sub_main {
    min-height: 170px;
  }

  .edie_body_section img {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .new_blogmain_sec .containerGrid {
    grid-gap: 20px;
  }

  .blog_publish_date {
    font-size: 14px;
  }

  .tech_sec .grid-container {
    grid-template-columns: repeat(3, 2fr 2fr);
  }

  .new_partners_img_txt_section {
    margin-bottom: 0px;
  }

  .lets_connectnew_lft_sec span {
    font-size: 22px;
  }
}

@media (min-width: 464px) and (max-width: 767px) {
  .our_clients_Section img,
  .home_expert img {
    width: 100%;
    padding: 20px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .background-video video {
    min-height: 600px;
  }

  .new_banner_main h1 {
    margin-bottom: 50px;
    font-size: 38px;
    line-height: 48px;
  }

  .whatwedo_sub_body_sec {
    min-height: auto;
  }

  .partners_new_Sec h2 {
    margin-top: 0px;
    font-size: 30px;
    line-height: 40px;
  }

  .whitepaper_txt_div {
    padding: 10px 0px 0px 10px;
  }

  .whitepaper_txt_div h5 {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    font-size: 20px;
    line-height: 32px;
    margin-top: 0px;
    padding-right: 0px;
  }

  .new_blog_lft_main h3 {
    font-size: 28px !important;
  }

  .howcan_help_txt {
    font-size: 15px;
    padding: 10px;
    line-height: 26px;
    width: 80%;
  }

  .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .new_blogs_main_div .blogDate {
    margin-left: -10px;
  }

  .new_what_we_do_section .what_wedo_sub_main {
    margin-bottom: 100px;
  }

  .new_what_we_do_section .what_wedo_sub_main img {
    width: 100%;
  }

  .whitepapaer_rht_div {
    padding: 20px;
  }

  .whitepaper_txt_div h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .whitepaper_txt_div a {
    margin-top: 0px;
  }

  .new_blogs_main_div .blogSection {
    gap: 5px;
  }

  .new_blogs_main_div .blogBody {
    padding: 0px 10px;
    height: auto;
    min-height: 300px;
  }

  .new_blog_lft_main h2 {
    font-size: 38px;
  }

  .how_Can_txt {
    font-size: 30px;
  }

  .lets_talk_close_div button {
    margin-right: 50px !important;
  }

  .new_what_we_do_section {
    padding: 30px 0px;
  }

  .new_what_we_do_head h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .new_what_we_do_head p {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .news_partners_new_section {
    padding: 30px 0px;
  }

  .expertise_section {
    padding: 30px 0px;
  }

  .expertise_section .section_title {
    margin-bottom: 30px;
  }

  .expertise_section {
    margin-bottom: 30px;
  }

  .expert_logos_new {
    margin-bottom: 0px;
  }

  .new_partners_img_txt_section {
    padding: 50px 0px 50px 0px;
  }

  .new_blogs_section {
    padding: 50px 0px;
  }

  .howcan_main {
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .news_partners_new_main img {
    margin-bottom: 30px;
  }

  .new_services_ban_btm_section p {
    padding-right: 0px;
    padding-top: 0px;
    font-size: 20px;
    line-height: 28px;
  }

  .product_eng_txt_main h1 {
    font-size: 30px;
  }

  .product_eng_txt_main {
    padding-left: 10px;
  }

  .product_eng_txt_main p {
    font-size: 16px;
  }

  .new_serve_product_section .expertise_sub ul li {
    font-size: 14px;
  }

  .new_services_banner .banner_main h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .new_services_ban_btm_section {
    margin-bottom: 30px;
    padding: 0px 0px 30px 0px;
  }

  .new_serve_product_section img {
    padding: 0px;
    margin-bottom: 20px;
  }

  .new_serve_product_section .expert_btm_btn {
    margin-bottom: 30px;
  }

  .new_services_tab__list ul li a {
    font-size: 16px;
    margin-right: 30px !important;
  }

  .col-12.col-sm-6.offset-sm-6.new_services_tabs {
    width: 100%;
    margin-left: 0px;
  }

  .new_cloud_servies_bg:before,
  .new_cloud_sec::before {
    display: none !important;
  }

  section.new_services_ban_btm_section.new_cloud_sec.mar_btm_80 {
    margin-bottom: 0px !important;
    padding: 20px 0px;
  }

  .new_cloud_servies_bg {
    margin-bottom: 0px !important;
    padding: 30px 0px 0 0;
  }

  .new_loud_enb_help {
    margin-top: 100px;
  }

  .new_whatwe_do_txt {
    margin-bottom: 0px;
  }

  .new_cloud_sec .product_eng_txt_main {
    margin-bottom: 50px;
  }

  .new_cloud_servies_bg img {
    position: relative;
    margin-bottom: 20px;
  }

  .new_cloud_servies_bg .expertise_rht,
  .new_cloud_sec .expertise_rht {
    padding: 0;
  }

  .news_partners_readmore {
    bottom: 15px;
  }

  .news_part_sub {
    margin-bottom: 30px;
  }

  .news_part_sub:last-child {
    margin-bottom: 0px;
  }

  .expertise_mar {
    margin-bottom: 0px !important;
    padding-top: 50px;
  }

  .our_clients_new img {
    width: 100%;
  }

  .expertise_mar .react-multi-carousel-item img {
    width: 100%;
  }

  .enl_part_img_div {
    top: 70px;
    position: unset;
    margin-top: 30px;
  }

  .client_logos_section {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .new_services_ban_btm_txt_sec {
    padding: 50px 0px;
  }

  .new_services_ban_btm_txt_sec p {
    font-size: 18px;
    line-height: 38px;
  }

  .serices_before_div {
    padding-top: 0px !important;
    margin-bottom: 50px !important;
  }

  .serices_before_div1 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .serices_before_div2 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .blog_post_txt p {
    font-size: 16px;
    line-height: 26px;
  }

  .blog_post_txt {
    padding: 10px 15px;
    left: 10px;
  }

  .blog_post_img {
    max-height: 300px;
    height: 300px;
  }

  .blogpost_img_a {
    left: 10px;
  }

  .blog_post_a_div a {
    padding: 10px;
  }

  .blog_post_a_div {
    left: 10px;
  }

  .journeys_logos_section h1,
  .our_locations_section h1,
  .our_locations_section h2,
  .industry_expertise h2,
  .contactus_form_section h1 {
    font-size: 26px;
    line-height: 40px;
  }

  .industry_sub_main {
    min-height: auto;
  }

  .contactus_form_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .contactus_form_section h1 {
    margin-bottom: 20px;
  }

  .contactus_form_section {
    padding: 50px 0px;
  }

  .edie_body_section img {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .edie_body_section {
    padding-top: 20px;
  }

  .tech_sec .grid-container {
    grid-template-columns: repeat(2, 2fr 2fr);
  }

  .explore_hedding {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .innovation_sub_main_sec h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .innovation_sec2 {
    margin-bottom: 30px !important;
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .landingpage_section .carousel-item {
    min-height: 600px;
  }
  .related_blog_card_body {
    margin-bottom: 30px;
    min-height: auto;
  }

  .blog_detail_header_section h2,
  .blog_new_body_sec h2,
  .blog_new_body_sec h3,
  .related_blogs_txt {
    font-size: 24px;
    line-height: 32px;
  }

  .related_blogs_section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog_detail_rht_div h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .blog_new_body_sec .row {
    flex-direction: column-reverse;
  }

  .related_blog_img img {
    width: 100%;
  }

  .related_blog_btm_sec {
    position: relative;
    width: 100%;
  }

  .blog_detail_header_section span {
    font-size: 18px;
    line-height: 26px;
  }

  .blog_detail_header_section p,
  .blog_detail_rht_div ul li a,
  .blog_new_body_sec p,
  .blog_new_body_sec ul li,
  .related_blog_content h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .blog_detail_header_section button {
    font-size: 20px;
    line-height: 30px;
  }

  .blog_detail_rht_div ul li {
    margin-bottom: 10px;
  }

  .related_blog_content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .why_vassist_main_grid {
    grid-template-columns: 1fr;
  }

  .business_inteligence_slider_lft {
    border-radius: unset;
    padding-right: 15px;
    padding-left: 25px;
  }

  .business_inteligence_slider_rht img {
    height: auto;
  }

  .business_inteligence_slider h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .business_inteligence_slider ul li,
  .business_inteligence_slider p {
    font-size: 16px;
    line-height: 24px;
  }

  .innovationCarousel .carousel-item img {
    min-height: 720px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }

  .customized_app_section img {
    margin-bottom: 20px;
  }

  .business_sub_text h2,
  .customized_app_sub_head h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .bridgegap_sub_text,
  .business_sub_text p,
  .customized_app_sub_head p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .whothe_page_builder_section {
    padding: 50px 0px;
  }

  .bridge_gap_section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 220px;
    margin-bottom: 0px;
  }

  .whopage_build_img {
    position: relative;
    left: unset;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .landingpage_section button,
  .readyto_get_started_section p {
    margin-bottom: 30px;
  }

  .readyto_get_started_section .align_items_center {
    align-items: left;
  }

  .bridge_gap_section h2,
  .whothe_page_builder_section h2,
  .landingpage_section h2,
  .why_enl_pagebuild_section h2,
  .readyto_get_started_section h2,
  .faqs_section h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .landingpage_section h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .faqs_section .accordion-button {
    font-size: 20px;
    line-height: 28px;
  }

  .page_bulder_banner p,
  .whothe_page_builder_section h5,
  .why_enl_pagebuild_section p {
    font-size: 16px;
    line-height: 26px;
  }

  .landingpage_section {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .why_enl_pagebuild_section {
    padding: 60px 0px;
  }

  .our_story_box {
    min-height: 420px;
    padding: 25px 15px 0px 15px;
    margin-bottom: 30px;
  }

  .our_story_new_main {
    padding-bottom: 0px;
  }

  .our_story_box h3 {
    min-height: auto;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 100%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: auto;
    padding: 80px 20px 50px 20px;
    margin-bottom: 60px;
  }

  .ourstory_box_btm_img {
    display: inline-block;
    width: 100%;
    left: 0px;
    text-align: center;
  }

  .core_value_main_heading,
  .our_ceo_section_main h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .our_story_new_main h6,
  .new_core_sub_main_border h3,
  .our_story_box h3 {
    font-size: 18px;
    line-height: 26px;
  }

  .our_story_new_main p {
    font-size: 16px;
    line-height: 26px;
  }

  .mobility_enterprice_section {
    padding-bottom: 0px;
  }

  .mobility_enterprice_section .achive_sub_main:nth-child(5),
  .mobility_enterprice_section .achive_sub_main:nth-child(6),
  .mobility_enterprice_section .achive_sub_main:nth-child(7),
  .mobility_enterprice_section .achive_sub_main:nth-child(8) {
    border-bottom: unset;
  }

  .choosing_sec {
    padding-top: 0px !important;
  }

  .terms_content_body h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .terms_content_body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .terms_content_body p,
  .terms_content_body ul li {
    font-size: 16px;
    line-height: 30px;
  }

  .sucessstory_body_txt_div span {
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  .sucessstory_body_txt_div a {
    left: 10px;
    font-size: 16px;
  }

  .about_joureny_body h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .about_joureny_body {
    width: 100%;
  }

  .about_joureny_body p {
    font-size: 20px;
    line-height: 30px;
  }

  .new_partners_pad {
    margin-bottom: 0px;
  }

  .background-video video {
    min-height: 550px;
  }

  .loadmore_div {
    margin-bottom: 10px;
    padding-top: 20px;
  }

  .new_banner_main h1 {
    margin-bottom: 30px;
    font-size: 38px;
    line-height: 48px;
  }

  .whatwedo_sub_body_sec {
    min-height: auto;
  }

  .partners_new_Sec h2 {
    margin-top: 0px;
    font-size: 30px;
    line-height: 40px;
  }

  .whitepaper_txt_div {
    padding: 10px 0px 0px 10px;
  }

  .whitepaper_txt_div h5 {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    padding-right: 0px;
    font-size: 20px;
    margin-top: 0px;
  }

  .new_blog_lft_main h3 {
    font-size: 28px !important;
  }

  .howcan_help_txt {
    font-size: 15px;
    padding: 10px;
    line-height: 26px;
    width: 80%;
  }

  .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .new_blogs_main_div .blogDate {
    margin-left: -10px;
  }

  .new_what_we_do_section .what_wedo_sub_main {
    margin-bottom: 100px;
  }

  .new_what_we_do_section .what_wedo_sub_main img {
    width: 100%;
  }

  .whitepapaer_rht_div {
    padding: 20px;
  }

  .whitepaper_txt_div h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .whitepaper_txt_div a {
    margin-top: 0px;
  }

  .new_blogs_main_div .blogSection {
    gap: 5px;
  }

  .new_blogs_main_div .blogBody {
    padding: 0px 10px;
    height: auto;
    min-height: 300px;
  }

  .new_blog_lft_main h2 {
    font-size: 38px;
  }

  .how_Can_txt {
    font-size: 30px;
  }

  .lets_talk_close_div button {
    margin-right: 20px !important;
    padding-top: 20px;
  }

  .new_what_we_do_section {
    padding: 30px 0px;
  }

  .new_what_we_do_head h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .new_what_we_do_head p {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .news_partners_new_section {
    padding: 30px 0px;
  }

  .expertise_section {
    padding: 30px 0px;
  }

  .expertise_section .section_title {
    margin-bottom: 30px;
  }

  .expertise_section {
    margin-bottom: 30px;
  }

  .new_partners_img_txt_section {
    padding: 50px 0px 50px 0px;
  }

  .new_blogs_section {
    padding: 50px 0px;
  }

  .howcan_main {
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .news_partners_new_main img {
    margin-bottom: 30px;
  }

  .new_services_ban_btm_section p {
    padding-right: 0px;
    padding-top: 0px;
    font-size: 20px;
    line-height: 36px;
  }

  .product_eng_txt_main h1 {
    font-size: 30px;
  }

  .product_eng_txt_main {
    padding-left: 10px;
  }

  .product_eng_txt_main p {
    font-size: 16px;
  }

  .new_serve_product_section .expertise_sub ul li {
    font-size: 14px;
  }

  .new_services_banner .banner_main h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .new_services_ban_btm_section {
    margin-bottom: 30px;
    padding: 0px 0px 30px 0px;
  }

  .new_serve_product_section img {
    padding: 0px;
    margin-bottom: 20px;
  }

  .new_serve_product_section .expert_btm_btn {
    margin-bottom: 30px;
  }

  .new_services_tab__list ul li a {
    font-size: 16px;
    margin-right: 12px !important;
  }

  .col-12.col-sm-6.offset-sm-6.new_services_tabs {
    width: 100%;
    margin-left: 0px;
  }

  .product_engineering_banner .banner_main h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .new_whatwe_do_txt {
    width: 100% !important;
  }

  .new_whatwedo_Section .whatwedo_btn a {
    padding: 8px 12px;
  }

  .product_engineering_banner {
    padding: 0px 10px !important;
  }

  .whatwedo_sub_body_sec h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .new_prod_what_wedo_sub_main .whatwedo_sub_body_sec p {
    margin-bottom: 0px;
  }

  .new_how_wedo_sub_lft img {
    margin-bottom: 30px;
  }

  .new_prod_what_wedo_sub_main1 {
    border: unset;
    margin-bottom: 50px;
  }

  .new_whatwe_do_txt h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .new_whatwe_do_txt p {
    color: #717171;
    font-size: 17px;
    line-height: 26px;
  }

  .new_how_wedo_sub_lft h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .new_how_wedo_sub_lft p {
    font-size: 17px;
    line-height: 27px;
    color: #717171;
  }

  .new_how_wedo_sub_rht h1 {
    font-size: 18px;
    line-height: 28px;
  }

  .new_cloud_servies_bg:before,
  .new_cloud_sec::before {
    display: none !important;
  }

  section.new_services_ban_btm_section.new_cloud_sec.mar_btm_80 {
    margin-bottom: 0px !important;
    padding: 20px 0px;
  }

  .new_cloud_servies_bg {
    margin-bottom: 0px !important;
    padding: 20px 0px 0 0;
  }

  .new_loud_enb_help {
    margin-top: 100px;
  }

  .new_whatwe_do_txt {
    margin-bottom: 0px;
  }

  .new_cloud_sec .product_eng_txt_main {
    margin-bottom: 50px;
  }

  .new_cloud_servies_bg img {
    position: relative;
    margin-bottom: 20px;
  }

  .new_cloud_servies_bg .expertise_rht,
  .new_cloud_sec .expertise_rht {
    padding: 0;
  }

  .new_cloud_sec p {
    font-size: 16px !important;
    line-height: 26px;
  }

  .expertise_mar .react-multi-carousel-item img {
    width: 100%;
  }

  .our_clients_new img {
    width: 100%;
  }

  .expertise_mar {
    margin-bottom: 0px;
  }

  .news_part_sub:last-child {
    margin-bottom: 0;
  }

  .news_partners_readmore {
    bottom: 15px;
  }

  .news_part_sub {
    margin-bottom: 20px;
  }

  .enl_part_img_div {
    top: 50px;
    position: unset;
    margin-top: 30px;
  }

  .new_services_ban_btm_txt_sec p {
    font-size: 18px;
    line-height: 38px;
  }

  .serices_before_div {
    padding-top: 0px !important;
    margin-bottom: 50px !important;
  }

  .serices_before_div1 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .serices_before_div2 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .blog_post_main {
    margin-bottom: 80px;
  }

  .blog_post_txt p {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .blog_post_txt {
    padding: 10px 15px;
  }

  .blog_post_img {
    max-height: 270px;
    height: 270px;
  }

  .blogpost_img_a {
    left: 10px;
  }

  .blog_post_a_div a {
    padding: 10px;
  }

  .blog_post_a_div {
    left: 10px;
  }

  .containerGrid {
    display: block;
  }

  .aboutnew_ban_head {
    font-size: 32px !important;
    line-height: 42px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 20px;
    line-height: 30px;
  }

  .core_values_sub_main h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  .aboutnew_unvelling h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_unvelling p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .aboutnew_ban_btm_txt_sec::before {
    width: 98%;
  }

  .corealues_title_section h1 {
    margin-bottom: 15px;
    font-size: 34px;
  }

  .core_values_sub {
    padding: 20px;
  }

  .about_unvelling_spark_section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .core_values_sub_main:nth-child(1),
  .core_values_sub_main:nth-child(3) {
    background: #000;
  }

  .core_values_sub_main:nth-child(3) h2,
  .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .core_values_sub_main:nth-child(4) h2,
  .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .core_values_sub_main:nth-child(4) {
    background: unset;
  }

  .aboutnew_ban_btm_txt_sec {
    margin-top: unset;
    padding-top: 40px !important;
    padding-bottom: 0px !important;
  }

  .journeys_logos_section h1,
  .our_locations_section h1,
  .our_locations_section h2,
  .industry_expertise h2,
  .contactus_form_section h1 {
    font-size: 26px;
    line-height: 40px;
  }

  .industry_sub_main {
    min-height: auto;
  }

  .contactus_form_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .contactus_form_section h1 {
    margin-bottom: 20px;
  }

  .contactus_form_section {
    padding: 50px 0px;
  }

  .contactus_form_section .form_main_div {
    width: 95%;
  }

  .whydata_title {
    font-size: 28px;
  }

  .achieve_competitive_section h2 {
    font-size: 28px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 20px;
    line-height: 30px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .whydata_lft_img {
    padding: 0;
    margin-bottom: 30px;
  }

  .data_preparation_rht_form {
    padding: 20px !important;
  }

  .achive_sub_main {
    border-right: unset;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .achive_bodydiv {
    padding: 10px 0 30px;
  }

  .data_preparation_form_section::before {
    background: unset;
    display: none;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(3) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(4) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .whydata_rht_main {
    display: block;
  }

  .data-preparation_tech .devoops_tech_flex {
    display: block;
  }

  .leveringthe_power_section .achive_sub_main:nth-child(1),
  .leveringthe_power_section .achive_sub_main:nth-child(2),
  .leveringthe_power_section .achive_sub_main:nth-child(3) {
    border-bottom: unset;
  }

  .sucessstory_sub_main {
    margin-bottom: 50px;
  }

  .sucessstory_sub_main img {
    width: 100%;
  }

  .sucessstory_sub_main:nth-child(2) {
    margin-bottom: 80px;
  }

  .sucessstory_sub_main:last-child {
    margin-bottom: 0;
  }

  .edie_body_section img {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .edie_body_section {
    padding-top: 50px;
  }

  .edie_body_section h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .explore_hedding {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .innovation_sub_main_sec h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .innovation_sec2 {
    margin-bottom: 30px !important;
  }

  .innovation_sec2 p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .customized_app_section {
    padding: 40px 0px 40px 0px;
  }

  .vassist_faqs_section {
    padding: 40px 0px;
  }

  .business_inteligence_section {
    padding-top: 40px;
  }
}
@media (min-width: 320px) and (max-width: 367px) {
  .conversationSection span {
    font-size: 20px;
  }
}
@media (min-width: 368px) and (max-width: 479px) {
  .conversationSection span {
    font-size: 25px;
  }
}
@media (min-width: 320px) and (max-width: 479px) {
  .related_blog_card_body {
    margin-bottom: 30px;
    min-height: auto;
  }
  .breadcrumb_last_element {
    max-width: 120px;
  }
  .breadcrumb_element {
    max-width: 60px;
  }

  .blog_detail_header_section h2,
  .blog_new_body_sec h2,
  .blog_new_body_sec h3,
  .related_blogs_txt {
    font-size: 24px;
    line-height: 32px;
  }

  .related_blogs_section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog_detail_rht_div h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .blog_new_body_sec .row {
    flex-direction: column-reverse;
  }

  .related_blog_img img {
    width: 100%;
  }

  .related_blog_btm_sec {
    position: relative;
    width: 100%;
  }

  .blog_detail_header_section span {
    font-size: 18px;
    line-height: 26px;
  }

  .blog_detail_header_section p,
  .blog_detail_rht_div ul li a,
  .blog_new_body_sec p,
  .blog_new_body_sec ul li,
  .related_blog_content h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .blog_detail_header_section button {
    font-size: 20px;
    line-height: 30px;
  }

  .blog_detail_rht_div ul li {
    margin-bottom: 10px;
  }

  .related_blog_content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .innovationCarousel .carousel-item img {
    min-height: 800px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }

  .why_vassist_main_grid {
    grid-template-columns: 1fr;
  }

  .business_inteligence_slider_lft {
    border-radius: unset;
    padding-right: 15px;
    padding-left: 25px;
  }

  .business_inteligence_slider_rht img {
    height: auto;
  }

  .business_inteligence_slider h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .business_inteligence_slider ul li,
  .business_inteligence_slider p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .pull-right {
    margin-top: 150px;
    margin-left: -302px;
  }

  .landingpage_section .carousel-item {
    min-height: 600px;
  }
  .why_vassist_main_grid {
    grid-template-columns: 1fr;
  }

  .business_inteligence_slider_lft {
    border-radius: unset;
    padding-right: 15px;
    padding-left: 25px;
  }

  .business_inteligence_slider_rht img {
    height: auto;
  }

  .business_inteligence_slider h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .business_inteligence_slider ul li,
  .business_inteligence_slider p {
    font-size: 16px;
    line-height: 24px;
  }

  .customized_app_section img {
    margin-bottom: 20px;
  }

  .business_sub_text h2,
  .customized_app_sub_head h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .bridgegap_sub_text,
  .business_sub_text p,
  .customized_app_sub_head p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 250px;
    margin-bottom: 0px;
  }

  .whopage_build_img {
    position: relative;
    left: unset;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .landingpage_section button,
  .readyto_get_started_section p {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .readyto_get_started_section .align_items_center {
    align-items: left;
  }

  .bridge_gap_section h2,
  .whothe_page_builder_section h2,
  .landingpage_section h2,
  .why_enl_pagebuild_section h2,
  .readyto_get_started_section h2,
  .faqs_section h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .landingpage_section h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .faqs_section .accordion-button {
    font-size: 18px;
    line-height: 26px;
  }

  .page_bulder_banner p,
  .whothe_page_builder_section h5,
  .why_enl_pagebuild_section p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 5px;
  }

  .readyto_get_started_section button {
    font-size: 16px;
  }

  .bridge_gap_section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .bridgegap_sub_text {
    font-size: 16px;
    line-height: 26px;
  }

  .whothe_page_builder_section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .landingpage_section {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .page_bulder_banner {
    min-height: 600px !important;
  }

  .our_story_box {
    min-height: 500px;
    padding: 25px 15px 0px 15px;
    margin-bottom: 30px;
  }

  .our_story_new_main {
    padding-bottom: 0px;
    padding-top: 30px;
  }

  .our_story_box h3 {
    min-height: auto;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 100%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: auto;
    padding: 80px 20px 30px 20px;
    margin-bottom: 60px;
  }

  .ourstory_box_btm_img {
    display: inline-block;
    width: 100%;
    left: 0px;
    text-align: center;
  }

  .core_value_main_heading,
  .our_ceo_section_main h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .our_story_new_main h6,
  .new_core_sub_main_border h3,
  .our_story_box h3 {
    font-size: 16px;
    line-height: 26px;
  }

  .our_story_new_main p,
  .our_ceo_section_main h5,
  .our_ceo_section_main p {
    font-size: 16px;
    line-height: 26px;
  }

  .edie_body_section h2 {
    font-size: 26px !important;
    line-height: 36px !important;
  }

  .migration_reason .achive_sub_div img {
    margin-bottom: 10px;
  }

  .terms_content_body h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .terms_content_body {
    padding-left: 10px;
    padding-right: 10px;
  }

  .terms_content_body p,
  .terms_content_body ul li {
    font-size: 16px;
    line-height: 30px;
  }

  .new_services_tab__list {
    padding: 20px 0px 20px 10px;
  }

  .sucessstory_body_txt_div span {
    padding: 15px;
    font-size: 16px;
    line-height: 26px;
  }

  .sucessstory_body_txt_div a {
    bottom: -10px;
  }

  .success_storie_p {
    font-size: 20px;
    line-height: 30px;
  }

  .services_martop_lets {
    margin-top: 60px;
  }

  .letstalk_new_lft p {
    font-size: 16px;
    line-height: 26px;
  }

  .lets_talk_newform_rht h2 {
    font-size: 18px;
    line-height: 28px;
  }

  .about_joureny_body h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .about_joureny_body {
    width: 100%;
  }

  .about_joureny_body p {
    font-size: 16px;
    line-height: 26px;
  }

  .headquaters_flex {
    display: block !important;
    text-align: center;
  }

  .headquaters_sec img {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .our_clients_Section img {
    width: 100%;
    padding: 20px;
  }

  .new_partners_pad {
    margin-bottom: 0px;
  }

  .background-video video {
    min-height: 500px;
  }

  .home_expert .react-multi-carousel-item {
    text-align: center;
  }

  .home_expert .react-multi-carousel-item img {
    width: 100%;
  }

  .new_banner_main h1 {
    margin-bottom: 30px;
    font-size: 26px !important;
    line-height: 36px !important;
  }

  .homebanmar .row {
    margin-top: 30px;
  }

  .new_banner_main span {
    font-size: 16px;
    line-height: 22px;
  }

  .whatwedo_sub_body_sec {
    min-height: auto;
  }

  .partners_new_Sec h2 {
    margin-top: 0px;
    font-size: 30px;
    line-height: 40px;
  }

  .whitepaper_txt_div {
    padding: 10px 0px 0px 10px;
  }

  .whitepaper_txt_div h5 {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .enl_partner_sub_txt {
    padding-top: 0px;
    font-size: 16px;
    margin-top: -50px;
  }

  .new_blog_lft_main h3 {
    font-size: 28px !important;
  }

  .howcan_help_txt {
    font-size: 16px;
    padding: 10px;
    line-height: 26px;
    width: 95%;
    margin-bottom: 20px;
    position: relative;
    top: 0;
  }

  .new_how_can_help_section {
    padding: 30px 0px;
  }

  .section_title {
    font-size: 26px;
    line-height: 36px;
  }

  .new_lets_talk_row .how_can_txt {
    font-size: 16px;
    line-height: 26px;
  }

  .new_blogs_main_div .blogDate {
    margin-left: -10px;
  }

  .new_what_we_do_section .what_wedo_sub_main {
    margin-bottom: 100px;
  }

  .new_what_we_do_section .what_wedo_sub_main img {
    width: 100%;
  }

  .whitepapaer_rht_div {
    padding: 20px;
    display: block;
  }

  .whitepaper_txt_div h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .whitepaper_txt_div a {
    margin-top: 0px;
  }

  .new_blogs_main_div .blogSection {
    gap: 5px;
    display: block;
  }

  .new_blogs_main_div .blogBody {
    padding: 0px 10px;
    height: auto;
    min-height: 300px;
  }

  .new_blog_lft_main h2 {
    font-size: 38px;
  }

  .how_Can_txt {
    font-size: 30px;
  }

  .lets_talk_close_div button {
    margin-right: 40px !important;
    padding-top: 0px;
  }

  .new_what_we_do_section {
    padding: 30px 0px;
  }

  .new_what_we_do_head h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .new_what_we_do_head p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .news_partners_new_section {
    padding: 30px 0px;
  }

  .expertise_section {
    padding: 30px 0px;
  }

  .expertise_section .section_title {
    margin-bottom: 30px;
  }

  .expertise_section {
    margin-bottom: 30px;
  }

  .new_partners_img_txt_section {
    padding: 50px 0px 50px 0px;
  }

  .new_blogs_section {
    padding: 50px 0px;
  }

  .howcan_main {
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .news_partners_new_main img {
    margin-bottom: 30px;
  }

  .new_blogs_main_div .blogBox {
    width: 100%;
  }

  .lets_talk_form_lft {
    padding-right: 0px;
  }

  .lets_talk_form_rht {
    padding-left: 0px;
  }

  .new_services_ban_btm_section p {
    padding-right: 0px;
    padding-top: 0px;
    font-size: 20px;
    line-height: 36px;
  }

  .product_eng_txt_main h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .new_prod_how_wedo_section {
    padding: 30px 0px;
  }

  .product_eng_txt_main {
    padding-left: 0px;
  }

  .product_eng_txt_main p {
    font-size: 16px;
    line-height: 26px;
  }

  .new_serve_product_section .expertise_sub ul li {
    font-size: 14px;
  }

  .new_services_banner .banner_main h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .new_services_ban_btm_section {
    margin-bottom: 30px;
    padding: 0px 0px 30px 0px;
  }

  .new_serve_product_section img {
    padding: 0px;
    margin-bottom: 20px;
  }

  .new_serve_product_section .expert_btm_btn {
    margin-bottom: 30px;
  }

  .new_serve_product_section .expertise_sub {
    display: block;
  }

  .new_services_tab__list ul li a {
    font-size: 14px;
    margin-right: 15px !important;
    line-height: 26px;
  }

  .col-12.col-sm-6.offset-sm-6.new_services_tabs {
    width: 100%;
    margin-left: 0px;
  }

  .product_engineering_banner .banner_main h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .new_whatwe_do_txt {
    width: 100% !important;
  }

  .new_whatwedo_Section .whatwedo_btn a {
    padding: 8px 12px;
  }

  .product_engineering_banner {
    padding: 0px 10px !important;
  }

  .whatwedo_sub_body_sec h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .new_prod_what_wedo_sub_main .whatwedo_sub_body_sec p {
    margin-bottom: 0px;
  }

  .new_how_wedo_sub_lft img {
    margin-bottom: 30px;
  }

  .new_prod_what_wedo_sub_main1 {
    border: unset;
    margin-bottom: 50px;
  }

  .prod_whatwedo_btm_sub_txt p {
    font-size: 16px;
    line-height: 26px;
  }

  .new_whatwe_do_txt h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .new_whatwe_do_txt p {
    color: #717171;
    font-size: 16px;
    line-height: 26px;
  }

  .new_whatwedo_Section {
    padding: 20px 0px;
  }

  .new_how_wedo_sub_lft h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .new_how_wedo_sub_lft p {
    font-size: 17px;
    line-height: 27px;
    color: #717171;
  }

  .new_how_wedo_sub_rht h1 {
    font-size: 18px;
    line-height: 28px;
  }

  .new_cloud_servies_bg:before,
  .new_cloud_sec::before {
    display: none !important;
  }

  section.new_services_ban_btm_section.new_cloud_sec.mar_btm_80 {
    margin-bottom: 0px !important;
    padding: 20px 0px;
  }

  .new_cloud_servies_bg {
    margin-bottom: 0px !important;
    padding: 30px 0px 0 0;
  }

  .new_loud_enb_help {
    margin-top: 100px;
  }

  .new_whatwe_do_txt {
    margin-bottom: 0px;
  }

  .new_cloud_sec .product_eng_txt_main {
    margin-bottom: 20px;
  }

  .new_cloud_servies_bg img {
    position: relative;
    margin-bottom: 20px;
  }

  .new_cloud_servies_bg .expertise_rht,
  .new_cloud_sec .expertise_rht {
    padding: 0;
  }

  .new_cloud_sec p {
    font-size: 16px !important;
    line-height: 26px;
  }

  .expertise_mar .react-multi-carousel-item img {
    width: 100%;
  }

  .our_clients_new img {
    width: 100%;
  }

  .expertise_mar {
    margin-bottom: 0px;
  }

  .expertise_mar .react-multi-carousel-item {
    text-align: center;
  }

  .our_clients_new .react-multi-carousel-item {
    text-align: center;
  }

  .news_part_sub:last-child {
    margin-bottom: 0;
  }

  .enl_partner_sub_txt {
    margin-top: 0px;
    padding-right: 0px;
    line-height: 30px;
  }

  .new_whitepaper_div {
    padding-bottom: 25px;
  }

  .news_part_sub {
    margin-bottom: 20px;
  }

  .new_services_ban_btm_txt_sec p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .serices_before_div {
    padding-top: 0px !important;
    margin-bottom: 50px !important;
  }

  .serices_before_div1 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .serices_before_div2 {
    padding-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .serices_before_div .expertise_rht ul,
  .serices_before_div2 .expertise_rht ul {
    padding: 0px !important;
  }

  .blog_post_main {
    margin-bottom: 60px;
  }

  .blog_post_txt {
    min-height: auto;
  }

  .blog_post_main {
    margin-bottom: 80px;
  }

  .blog_post_txt p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .blog_post_txt {
    padding: 10px 15px;
    left: 0px;
  }

  .blog_post_img {
    max-height: 200px;
    height: 200px;
  }

  .blogpost_img_a {
    left: 10px;
  }

  .blog_post_a_div a {
    padding: 10px;
  }

  .blog_post_a_div {
    left: 10px;
  }

  .blogs_loadmore {
    margin-bottom: 0px;
  }

  .blogs_loadmore .blog_loadmore_btn_div {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .new_blogmain_sec {
    padding-bottom: 0px;
  }

  .containerGrid {
    display: block;
  }

  .aboutnew_ban_head {
    font-size: 32px !important;
    line-height: 42px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 16px;
    line-height: 26px;
  }

  .core_values_sub_main h2 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .aboutnew_unvelling h2,
  .example_imgs_section h5 {
    font-size: 22px;
    line-height: 30px;
  }

  .aboutnew_unvelling p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .aboutnew_ban_btm_txt_sec::before {
    width: 98%;
  }

  .corealues_title_section h1 {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 40px;
  }

  .core_values_sub {
    padding: 20px 0px;
  }

  .about_unvelling_spark_section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .core_values_sub_main:nth-child(1),
  .core_values_sub_main:nth-child(3) {
    background: #000;
  }

  .core_values_sub_main:nth-child(3) h2,
  .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .core_values_sub_main:nth-child(4) h2,
  .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .core_values_sub_main:nth-child(4) {
    background: unset;
  }

  .aboutnew_ban_btm_txt_sec {
    margin-top: unset;
    padding-top: 23px !important;
    padding-bottom: 0px !important;
  }

  .journeys_logos_section h1,
  .our_locations_section h1,
  .our_locations_section h2,
  .industry_expertise h2,
  .contactus_form_section h1 {
    font-size: 26px;
    line-height: 40px;
  }

  .industry_sub_main {
    min-height: auto;
  }

  .contactus_form_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .contactus_form_section h1 {
    margin-bottom: 20px;
  }

  .contactus_form_section {
    padding: 50px 0px;
  }

  .contactus_form_section .form_main_div {
    width: 95%;
  }

  .industry_expertise_section {
    padding: 25px;
  }

  .contactus_form_section .form_main {
    display: block !important;
  }

  .contactus_form_section select {
    margin-bottom: 30px;
  }

  .whydata_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px !important;
  }

  .whydata_preparation_section {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .project_highlets_section h1 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
  }

  .liquor_section h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .industries_healthare h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .successstory_section h2,
  .sucess_storie_title {
    font-size: 28px;
    line-height: 38px;
  }

  .list_items_main li,
  .new_cloud_servies_bg p {
    font-size: 16px;
    line-height: 26px;
    padding-right: 0px;
  }

  .bigdata_services_section {
    margin-bottom: 0px;
  }

  .achieve_competitive_section h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 22px !important;
    line-height: 30px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 16px;
    line-height: 26px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 20px;
    line-height: 28px;
    padding-right: 0px;
  }

  .prod_new_whatwedo_btm_img_Section {
    margin-top: 10px;
  }

  .achieve_competitive_section {
    margin-bottom: 20px;
    padding-bottom: 0px;
  }

  .enterprice_mobility_section h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .enterprice_accelerating_section {
    padding-top: 20px;
  }

  .banner_main h1 {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 0px !important;
  }

  .new_banner_main h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .banner_section {
    min-height: 500px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .whydata_lft_img {
    padding: 0;
    margin-bottom: 30px;
  }

  .data_preparation_rht_form {
    padding: 20px !important;
  }

  .achive_sub_main {
    border-right: unset;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .achive_sub_main:nth-child(5),
  .achive_sub_main:nth-child(6),
  .achive_sub_main:nth-child(7),
  .achive_sub_main:nth-child(8) {
    border-top: 0px !important;
  }

  .achive_bodydiv {
    padding: 10px 0 15px;
  }

  .data_preparation_form_section::before {
    background: unset;
    display: none;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(3) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(3) p {
    color: #fff;
  }

  .our_competitive_edge_section .core_values_sub_main:nth-child(4) h2,
  .our_competitive_edge_section .core_values_sub_main:nth-child(4) p {
    color: #000;
  }

  .whydata_rht_main {
    display: block;
  }

  .data-preparation_tech .devoops_tech_flex {
    display: block;
  }

  .leveringthe_power_section .achive_sub_main:nth-child(1),
  .leveringthe_power_section .achive_sub_main:nth-child(2),
  .leveringthe_power_section .achive_sub_main:nth-child(3) {
    border-bottom: unset;
  }

  .sucessstory_sub_main {
    margin-bottom: 50px;
  }

  .sucessstory_sub_main img {
    width: 100%;
  }

  .sucessstory_sub_main:nth-child(2) {
    margin-bottom: 80px;
  }

  .sucessstory_sub_main:last-child {
    margin-bottom: 0;
  }

  .sucessstory_body_txt_div {
    padding: 12px;
  }

  .edie_body_section img {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }

  .edie_body_section {
    padding-top: 50px;
  }

  .edie_body_section h2,
  .leadership_carousel_section h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .news_partners_readmore {
    bottom: 5px;
  }

  .tech_sec .grid-container {
    grid-template-columns: repeat(1, 2fr 2fr);
  }

  .enl_part_img_div {
    top: 20px;
  }

  .lets_connectnew_lft_sec span {
    font-size: 20px;
  }

  .explore_hedding {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .innovation_sub_main_sec h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .innovation_sec2 {
    margin-bottom: 30px !important;
  }

  .innovation_sec2 p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .customized_app_section {
    padding: 40px 0px 40px 0px;
  }

  .vassist_faqs_section {
    padding: 40px 0px;
  }

  .business_inteligence_section {
    padding-top: 40px;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .new_services_ban_btm_section p {
    font-size: 26px;
  }

  .new_serve_digital p {
    font-size: 20px;
    line-height: 30px;
  }

  .new_services_tab__list ul li a {
    font-size: 16px;
    margin-right: 12px;
  }

  .blog_post_txt p {
    font-size: 20px;
  }

  .aboutnew_ban_head {
    font-size: 42px !important;
    line-height: 52px !important;
  }

  .aboutnew_ban_btm_txt_sec h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .aboutnew_ban_btm_txt_sec p {
    font-size: 24px;
    line-height: 34px;
  }

  .core_values_sub_main h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .core_values_sub_main p {
    padding-bottom: 0px;
  }

  .aboutnew_unvelling h2 {
    font-size: 32px;
    line-height: 46px;
  }

  .aboutnew_unvelling p {
    font-size: 22px;
    line-height: 32px;
  }

  .ourt_team_names_top {
    padding: 15px;
  }

  .whydata_title {
    font-size: 28px;
  }

  .achieve_competitive_section h2 {
    font-weight: 28px;
  }

  .dataquality_section h2,
  .the_strength_txt_section h2,
  .trifacta_animated_section h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_title h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .our_competitive_edge_section .core_values_sub_main h2,
  .datapreparation_embark_section h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .our_competitive_edge_section .core_values_sub_main p {
    font-size: 20px;
    line-height: 30px;
  }

  .datapreparation_embark_section .embark_text_div h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .trifacta_animated_section ul li {
    font-size: 20px;
    line-height: 30px;
  }

  .data_preparation_lft_body h2 {
    font-size: 34px;
    line-height: 50px;
  }

  .data_preparation_lft_body h5 {
    font-size: 26px;
  }

  .edie_body_section p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .new_banner_main h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .data_cycle_box p {
    font-size: 15px;
    line-height: 25px;
  }

  .datapreparation_main_section {
    max-width: 1150px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .landingpage_section .carousel-item {
    min-height: 400px;
  }
  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 320px;
  }

  .our_story_box {
    min-height: 630px;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 90%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: 250px;
    padding: 80px 15px 20px 15px;
  }

  .blogs_mainbody_gap {
    width: 83%;
    margin: 0 auto;
  }

  .data_cycle_box p {
    font-size: 14px;
    line-height: 24px;
  }

  .datapreparation_main_section {
    max-width: 960px;
  }

  .full-screen-carousel .flip-card-back {
    padding: 20px 6px;
  }

  .full-screen-carousel .flip-card-back p {
    font-size: 15px;
    line-height: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .landingpage_section .carousel-item {
    min-height: 400px;
  }
  .innovationCarousel .carousel-item img {
    min-height: 600px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }

  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 320px;
  }

  .our_story_box {
    min-height: 630px;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 90%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: 250px;
    padding: 80px 15px 20px 15px;
  }

  .blogs_mainbody_gap {
    width: 86%;
    margin: 0 auto;
  }

  .full-screen-carousel .flip-card-back {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 25px;
  }

  .leadership_slide .flip-card-front img {
    width: 100%;
  }

  .about_joureny_body h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .data_cycle_box p {
    font-size: 14px;
    line-height: 24px;
  }

  .datapreparation_main_section {
    max-width: 950px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blogs_space {
    padding-left: 0px;
  }

  .why_vassist_main_grid {
    grid-template-columns: 1fr;
  }

  .business_inteligence_slider_lft {
    border-radius: unset;
    padding-right: 15px;
  }

  .business_inteligence_slider_rht {
    border-radius: unset;
  }

  .business_inteligence_slider_rht img {
    height: auto;
  }

  .innovationCarousel .carousel-item img {
    min-height: 550px;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }

  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 250px;
  }

  .whopage_build_img {
    position: relative;
    left: unset;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .our_story_box {
    min-height: 725px;
    padding: 25px 15px 0px 15px;
  }

  .our_ceo_section_main {
    min-height: 460px;
  }

  .our_story_new_boxes_main {
    width: 100%;
  }

  .new_core_sub_main_border {
    margin-right: 0px;
    min-height: 300px;
    padding: 80px 10px 20px 10px;
  }

  .blogs_mainbody_gap {
    width: 90%;
    margin: 0 auto;
  }

  .new_blog_thumb_row h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .new_our_story_main_section h2,
  .new_core_headding,
  .leadership_carousel_section h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .new_our_story_main_section h3 {
    font-size: 22px;
    line-height: 32px;
  }

  .new_our_story_main_section p {
    font-size: 16px;
    line-height: 28px;
  }

  .new_core_lft_p {
    font-size: 22px;
    line-height: 32px;
    padding-right: 10px;
  }

  .core_rht_cnt_sub p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  .loadmore_div {
    margin-bottom: 10px;
  }

  .services_martop_lets {
    margin-top: 60px;
  }

  .about_joureny_body h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .about_joureny_body {
    width: 90%;
  }

  .caption_div p {
    font-size: 22px;
    line-height: 32px;
  }

  .data_cycle_box p {
    font-size: 16px;
    line-height: 26px;
  }

  .datapreparation_main_section {
    max-width: 950px;
    display: block;
    height: auto;
  }

  .dataprepare_sub_lft {
    display: block;
    width: 100%;
  }

  .dataprepare_rht_setion {
    display: block;
    width: 100%;
    padding: 0px 30px;
  }

  .data_cycle_box_main {
    padding-top: 0px;
    padding-bottom: 60px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .search-container {
    position: relative;
    max-width: 859px;
    margin-bottom: -46px;
    margin-left: -3px;
    margin-top: 22px;
  }
  .lets_talk_newform_rht {
    margin-left: 0;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 30px;
    width: 85%;
    max-width: 360px;
    min-width: unset;
    padding: 15px 20px;
  }
  .blog_details_lft_new_div {
    padding-right: 12px;
  }
  .new_blog_thumb_row h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .blogsmain_lft_pad_lft0 {
    padding-left: 0px;
  }

  .blogs_space {
    padding-left: 0px;
  }

  .new_blog_thumb_row img {
    margin-bottom: 15px;
  }

  .blogs_sub_date_text p {
    margin-bottom: 0px;
  }

  .new_blog_thumb_row p,
  .blogs_sub_date_text span {
    font-size: 16px;
    line-height: 24px;
  }

  .lets_talk_bg {
    background: unset !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .data_preparation_form_section {
    background: #f3f4f5;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px;
    margin-top: 12px;
  }

  .data_preparation_rht_form input,
  .data_preparation_rht_form select {
    background: unset;
  }

  .leveringthe_power_section .achive_sub_main {
    border-bottom: 1px solid #ccc !important;
  }

  .leveringthe_power_section .achive_sub_main:last-child {
    border-bottom: 0px !important;
    margin-bottom: 0px;
  }

  .leveringthe_power_section .achive_sub_main {
    padding-top: 10px !important;
  }

  .leveringthe_power_section .achive_sub_main:nth-child(4),
  .leveringthe_power_section .achive_sub_main:nth-child(5),
  .leveringthe_power_section .achive_sub_main:nth-child(6) {
    padding-top: 10px !important;
  }

  .leveringthe_power_section .achive_sub_main:nth-child(4) {
    border-right: 0px !important;
  }

  .letstalk_imglft {
    display: none;
  }

  .letstalk_new_lft p {
    margin-bottom: 0px;
  }

  .letstalk_p_btm_mar {
    margin-bottom: 30px !important;
    display: block;
  }

  .datapreparation_main_section {
    max-width: 950px;
    display: block;
    height: auto;
  }

  .dataprepare_sub_lft {
    display: block;
    width: 100%;
  }

  .dataprepare_rht_setion {
    display: block;
    width: 100%;
    padding: 0px 15px;
  }

  .data_cycle_box_main {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .data_cycle_box h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .headquaters_flex {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .headquaters_sec img {
    margin-right: 0px;
  }
}

@media (min-width: 320px) and (max-width: 599px) {
  .related_blogs_head {
    margin-left: 0px;
    padding-left: 15px;
  }
  .landingpage_section p {
    font-size: 16px;
    line-height: 24px;
  }
  .blogs_mainbody_gap {
    width: 100%;
  }
  .landingpage_section .carousel-control-next,
  .landingpage_section .carousel-control-prev {
    display: none;
  }
  .landingpage_section h2 {
    margin-bottom: 25px;
  }
  .blogs_sub_date_text {
    gap: 40px;
  }

  .new_blog_thumb_row h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .achieve_competitive_section {
    padding: 20px 0px !important;
  }

  .whydata_txt_div h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .whydata_title {
    font-size: 22px;
    line-height: 30px;
  }

  .achieve_competitive_section h2,
  .product_eng_txt_main h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .unlock_benifits_section {
    padding-top: 0px;
  }

  .achive_head {
    display: block;
  }

  .whydata_txt_div p {
    padding-right: 0px;
    margin-bottom: 10px;
  }

  .unlock_benifits_section img {
    margin-bottom: 10px;
  }

  .devoops_tech h2,
  .competittive_edge_title,
  .howwe_solvenew_section_body h3,
  .bigdata_services_section h2,
  .project_highlets_section h1,
  .liquor_section h2,
  .industries_healthare h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .successstory_section h2,
  .sucess_storie_title,
  .data_preparation_lft_body h5,
  .edie_body_section h2 {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .success_storie_p {
    font-size: 16px;
    line-height: 26px;
  }

  .sucess_storie_title {
    margin-bottom: 0px;
  }

  .industries_learnbtn a {
    padding: 12px 30px;
  }

  .success_story_main {
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .success_stories_section {
    padding-top: 50px;
  }

  .success_body_top_gap {
    margin-top: 40px;
  }

  .new_core_headding {
    font-size: 22px !important;
    line-height: 30px !important;
    margin-bottom: 10px;
  }

  .new_our_story_main_section h2,
  .new_our_story_main_section h3,
  .leadership_carousel_section h2 {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .leadership_carousel_section {
    padding-top: 20px;
  }

  .conclusion_section h5 {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .blogdetail_blogsa_section {
    padding-bottom: 40px;
  }

  .new_what_we_do_head h2 {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .section_title {
    margin-bottom: 10px;
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .ourpartner_slide_p {
    font-size: 18px;
    line-height: 28px;
  }

  .new_banner_main h1 {
    margin-bottom: 10px;
  }

  .whatwedo_sub_text_bg {
    left: 15px;
    padding: 15px !important;
  }

  .list_items_main li {
    font-size: 16px;
    line-height: 26px;
  }

  .letstalk_new_lft h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .full-screen-carousel .flip-card-back {
    width: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .landingpage_section .carousel-item {
    min-height: 400px;
  }
  .our_story_box {
    min-height: 600px;
  }

  .our_ceo_section_main {
    min-height: 550px;
  }

  .bridgegap_sub_div .new_core_sub_main_border {
    min-height: 300px;
  }
}

@media screen and (max-width: 991px) {
  .vassistCarousel {
    grid-template-columns: 1fr !important;
  }

  .vassistCarouselSection .carousel-indicators {
    left: 50% !important;
    transform: translateX(-50%);
    width: 100%;
    margin: 15px 0;
  }

  .vassistCarousel img {
    border-radius: 0 0 20px 20px !important;
  }

  .vassistCarouselSection .carousel-control-next {
    display: none !important;
  }

  .vassistCarouselSection .carousel-control-prev {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .new_services_tab_section {
    background: #e3853b;
  }
  .new_services_tabs {
    width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .new_services_tab__list ul li button {
    font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .search-container {
    position: relative;
    max-width: 552px;
    margin-bottom: 20px;
    margin-left: 41px;
  }
}
@media (max-width: 768px) {
  .search-container {
    position: relative;
    max-width: 452px;
    margin-bottom: 20px;
    margin-left: 3px;
  }
  .all_stories_select_section h1 {
    font-size: 22px;
    line-height: 30px;
    margin-top: -85px;
}
}
@media (max-width: 1399px) {
  .search-container {
    position: relative;
    max-width: 739px;
    margin-bottom: -30px;
    margin-left: -9px;
  }  
}
@media (max-width: 1199px) {
  .search-container {
    position: relative;
    max-width: 621px;
    margin-bottom: 22px;
    margin-left: -11px;
  }  
}

