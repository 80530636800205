.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner_section {
  /* background: url(../src/images/Enlume-Banner-1.png) no-repeat center; */
  /* min-height: 700px; */
  background-size: cover;
  position: relative;
}

.banner_main {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffff;
  text-align: center;
  width: 100%;
}
.banner_main .row {
  margin: 0 auto;
  width: 75%;
}
.banner_main h1 {
  font-size: 42px;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}
.banner_main span {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased !important;
  margin-bottom: 25px;
  display: block;
}
.banner_main a {
  font-size: 13px;
  color: #fff;
  background-color: transparent;
  border: solid 2px #fff !important;
  padding: 15px 42px;
  letter-spacing: 2px !important;
  line-height: 20px;
  width: auto;
  border-radius: 50px;
  text-decoration: none;
  margin: 0 auto 0 auto;
  text-transform: uppercase !important;
  font-weight: 700;
  font-family: "MontserratBold";
  transition: 0.4s all ease-in-out;
  display: inline-block;
}
.banner_main a .fa-arrow-right {
  display: none;
}
.banner_main a:hover .fa-arrow-right {
  display: inline-block;
  transition: 0.2s all ease-in-out;
}
.banner_main a:hover {
  background: #f27f21;
  border: 2px solid #f27f21 !important;
}

#services-sub-menu,
#industries-sub-menu,
#resources-sub-menu {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#services:hover #services-sub-menu {
  display: block;
  opacity: 1;
}
#services:hover #services-sub-menu,
#services-sub-menu:hover {
  display: block !important;
  opacity: 1;
}

#industries:hover #industries-sub-menu,
#industries-sub-menu:hover {
  display: block !important;
  opacity: 1;
}

#resources:hover #resources-sub-menu,
#resources-sub-menu:hover {
  display: block !important;
  opacity: 1;
}
.blog-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 140px;
  color: orange;
}
